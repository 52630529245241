import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import ProviderReceiptsActionRequestFilters from "./ProviderReceiptsActionRequestFilters"
import { useDispatch, useSelector } from "react-redux"
import { ProvActionRequestAction } from "store/provider-action-requests/provider-action-request-slice"

const ProviderReceiptsActionRequestListComponents = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onAllDeleteClicked,
  onAdvancedSearchSubmit,
  onClickDetail,
  onCancelDeleteClicked,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const actionIds = useSelector(state => state.provActionRequests.ids)
  const dispatch = useDispatch()

  const onCheckboxClick = (e, id) => {
    const check = actionIds.includes(id)
    if (check) {
      const updated = actionIds.filter(myId => myId !== id)
      dispatch(ProvActionRequestAction.setIds(updated))
    } else {
      dispatch(ProvActionRequestAction.setNewIds(id))
    }
  }

  const onAllDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure delete?",
    })
    if (results.isConfirmed) {
      onAllDeleteClicked(actionIds)
    }
  }
  const onCancelDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure for cancel?",
    })
    if (results.isConfirmed) {
      onCancelDeleteClicked(actionIds)
    }
  }

  const onParentCheckChange = status => {
    if (status) {
      const all = data?.data.map(dt => dt.id)
      dispatch(ProvActionRequestAction.setIds(all))
    } else {
      dispatch(ProvActionRequestAction.resetIds())
    }
  }

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            deleteBtn={true}
            deleteBtnTitle={"Approve"}
            onAllDeleteHandler={onAllDeleteHandler}
            advnacedSearchBtn={true}
            cancelBtn={true}
            onCancelDeleteHandler={onCancelDeleteHandler}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "created_at", name: "Created at" },
              { value: "date", name: "Submission Date" },
            ]}
          />

          <Collapse isOpen={filterOpen}>
            <ProviderReceiptsActionRequestFilters
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            onParentCheckChange={onParentCheckChange}
            headers={[
              "SN",
              "Requested Account",
              "PROJECT",
              "PROVIDER",
              "AFGA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "AFGA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "UNIT PRICE (AS PER AFGA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "QUANTITY (AFGA UOM)",
              "QUANTITY (IP UOM)",
              "COMMENT",
              "RECEIPT DATE",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pIn, index) => (
              <tr key={index}>
                <th scope="row">
                  {" "}
                  <div className="form-check form-checkbox-outline form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`checkbox-${pIn.id}`}
                      checked={actionIds.includes(pIn.id)}
                      onChange={e => onCheckboxClick(e, pIn.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${pIn.id}`}
                    >
                      {data?.meta?.from + index}
                    </label>
                  </div>
                </th>
                <td>{pIn?.user?.email}</td>
                <td>{pIn?.project?.name}</td>
                <td>{pIn?.provider?.title}</td>
                <td>{pIn?.item?.unfp_item_name}</td>
                <td>{pIn?.item?.ip_item_description}</td>
                <td>
                  {pIn?.item.uom} &nbsp;
                  {pIn?.unit_uom_quantity_per_unfpa > 1 &&
                    pIn?.unit_uom_quantity_per_unfpa}
                </td>
                <td>
                  {pIn?.item.ip_unit_umo} &nbsp;
                  {pIn?.unit_umo_quantity_per_ip > 1 &&
                    pIn?.unit_umo_quantity_per_ipunit_umo_quantity_per_ip}
                </td>
                <td>{pIn.unit_price_per_unfpa}</td>
                <td>{pIn.unit_price_per_ip}</td>
                <td>{pIn.batch_number}</td>
                <td>{pIn.expire_date}</td>
                <td>{pIn.first_in_qty_per_unfpa}</td>
                <td>{pIn.first_in_qty_per_ip}</td>
                <td>{pIn.lost_comment}</td>
                <td>{pIn.date}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <ActionColumns
                    enableDetail={true}
                    data={pIn}
                    onClickDetail={onClickDetail}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderReceiptsActionRequestListComponents
