import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import { result } from "lodash"
import SAlert from "components/Alert"
import ProviderInFilter from "./ProviderOutFilter"
import ProviderOutTotalBox from "./ProviderOutTotalBox"
import { downloadURL } from "api/APP_URL"

const ProviderOutListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  onAllDeleteClicked,
  onAdvancedSearchSubmit,
  permissions,
  onClickDetail,
  exportStatus,
  onExportBtnClick,
}) => {
  const navigate = useNavigate()
  const [ids, setIds] = useState([])
  const [filterOpen, setFilterOpen] = useState(false)
  const onCheckboxClick = (e, id) => {
    const check = ids.includes(id)
    if (check) {
      const updated = ids.filter(myId => myId !== id)
      setIds(updated)
    } else {
      setIds(prev => {
        return [...prev, id]
      })
    }
  }

  const onAllDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure delete?",
    })
    if (results.isConfirmed) {
      onAllDeleteClicked(ids)
    }
  }

  const onParentCheckChange = status => {
    if (status) {
      const all = data?.data.map(dt => dt.id)
      setIds(all)
    } else {
      setIds([])
    }
  }

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={true}
            rightBtnTitle={"Add Distribution"}
            rightBtnRoute={"/add-provider-distributions"}
            deleteBtn={permissions.includes("delete-provider-in")}
            onAllDeleteHandler={onAllDeleteHandler}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            exportBtn={true}
            exportBtnLoading={exportStatus?.loading}
            onExportBtnClick={onExportBtnClick}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "ip_id", name: "IP" },
              { value: "province_id", name: "Province" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <ProviderOutTotalBox totals={data?.totals} />
          <Collapse isOpen={filterOpen}>
            <ProviderInFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            onParentCheckChange={onParentCheckChange}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "IP",
              "TRANSACTION TYPE",
              "DISTRIBUTOR",
              "WAREHOUSE DISRIBUTE",
              "PROVINCE",
              "FACILITY",
              "AFGA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "AFGA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "UNIT PRICE (AS PER AFGA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "DISTRIBUTED QTY PER IP	",
              "DISTRIBUTION NUMBER",
              "DISTRIBUTION DATE",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pOut, index) => {
              return (
                <tr key={index}>
                  <th scope="row">
                    {" "}
                    <div className="form-check form-checkbox-outline form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-${pOut.id}`}
                        checked={ids.includes(pOut.id)}
                        onChange={e => onCheckboxClick(e, pOut.id)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`checkbox-${pOut.id}`}
                      >
                        {data?.meta?.from + index}
                      </label>
                    </div>
                  </th>
                  <td>{pOut?.project?.name}</td>
                  <td>{pOut?.provider?.title}</td>
                  <td>{pOut?.ip?.name}</td>
                  <td>{pOut?.transaction_type}</td>
                  <td>{pOut?.distributor}</td>
                  <td>{pOut?.warehouse_disribute}</td>
                  <td>{pOut?.province?.province}</td>

                  <td>
                    {pOut?.facility?.facility_name &&
                    pOut?.facility?.facility_name != "Others"
                      ? pOut?.facility?.facility_name
                      : pOut?.other_hfs
                      ? `Others ( ${pOut?.other_hfs} )`
                      : ""}
                  </td>
                  <td>{pOut?.item?.unfp_item_name}</td>
                  <td>{pOut?.item?.ip_item_description}</td>
                  <td>
                    {pOut?.item.uom} &nbsp;
                    {pOut?.item.unfpa_uom_quantity > 1 &&
                      pOut?.item.unfpa_uom_quantity}
                  </td>
                  <td>
                    {pOut?.item.ip_unit_umo} &nbsp;
                    {pOut?.item.ip_uom_quantity > 1 &&
                      pOut?.item.ip_uom_quantity}
                  </td>
                  <td>{pOut?.provider_in?.unit_price_per_unfpa}</td>
                  <td>{pOut?.provider_in?.unit_price_per_ip}</td>
                  <td>{pOut?.provider_in?.batch_number}</td>
                  <td>{pOut?.provider_in?.expire_date}</td>
                  <td>{pOut?.out_quantity}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {pOut?.invoice_number}
                  </td>
                  <td>{pOut?.date}</td>
                  <td>
                    {pOut.deleteStatus === 0 ? (
                      <ActionColumns
                        data={pOut}
                        enableEdit={permissions.includes("update-provider-in")}
                        enableDelete={permissions.includes(
                          "delete-provider-in"
                        )}
                        enableDetail={true}
                        editRoute={`/edit-provider-distributions/${pOut.id}`}
                        onClickDetail={onClickDetail}
                        onClickDelete={onClickDelete}
                        enableDocumentLink={pOut?.document}
                        documentLink={`${downloadURL}/${pOut?.document}`}
                      />
                    ) : (
                      <Button className="btn btn-info btn-sm">Pending</Button>
                    )}
                  </td>
                </tr>
              )
            }}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderOutListComponent
