import axiosInstance from "api/axiosInstance"
import { providerDashboardAction } from "./provider-dashboard-slice"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"
import { batch } from "react-redux"

export const getProviderStock = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderStock({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-stock`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderStock({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderStock({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderIps = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderIps({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-ips`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderIps({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderIps({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderProjects = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderProjects({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-projects`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderProjects({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderProjects({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderItemCategories = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderItemCategories({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-itemCategories`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderItemCategories({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderItemCategories({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderItems = id => dispatch => {
  dispatch(
    providerDashboardAction.setProviderItems({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get(`/v1/get-provider-items/${id}`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderItems({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const getProviderItemsByMultipleCategories = ids => dispatch => {
  dispatch(
    providerDashboardAction.setProviderItems({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .post(`/v1/get-provider-items-by-multiple-categories`, { ids })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderItems({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const getProviderInItems = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderInItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-stock-ins`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderInItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderInItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderOutItems = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderOutItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-stock-outs`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderOutItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderOutItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderIpsStock = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderIpsStock({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-ips-stock`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderIpsStock({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderIpsStock({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const exportProviderIpsStock = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderIpsStockExport({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get(`/v1/export-provider-ips-stock`, { params: params ? params : {} })
    .then(response => {
      const dataBlob = new Blob([response?.data], {
        type: "application/pdf,",
      })
      FileSaver.saveAs(
        dataBlob,
        `Provider-IPs-Inventory-Report-${Math.random().toString()}.xlsx`
      )
      dispatch(
        providerDashboardAction.setProviderIpsStockExport({
          loading: false,
          error: null,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderIpsStockExport({
          loading: false,
          error: error?.response,
        })
      )
    })
}

export const getProviderIPsInItems = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderInItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-ips-stock-ins`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderInItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderInItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderIPsOutItems = params => dispatch => {
  dispatch(
    providerDashboardAction.setProviderOutItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-ips-stock-outs`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderOutItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderOutItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderWarehouses = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderWarehouses({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-warehouses`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderWarehouses({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderWarehouses({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderProviders = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderProviders({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-providers`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderProviders({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderProviders({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderProvinces = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderProvinces({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-provinces`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderProvinces({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderProvinces({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderFacilities = id => dispatch => {
  dispatch(
    providerDashboardAction.setProviderFacilities({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-facilities/${id}`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderFacilities({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderFacilities({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderFacilitiesByMultipleProvinces = ids => dispatch => {
  dispatch(
    providerDashboardAction.setProviderFacilities({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .post(`/v1/get-provider-facilities-by-multple-provinces`, { ids: ids })
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderFacilities({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderFacilities({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderToProviderPendingCount = () => dispatch => {
  dispatch(providerDashboardAction.setProviderToProviderPendingCount(0))
  axiosInstance
    .get("/v1/provider-to-provider-pendingCount")
    .then(response => {
      if (response) {
        batch(() => {
          dispatch(
            providerDashboardAction.setProviderToProviderPendingCount(
              response?.data
            )
          )
        })
      }
    })
    .catch(error => {
      dispatch(providerDashboardAction.setProviderToProviderPendingCount(0))
      console.log(error)
    })
}

export const getProviderDISNumbers = () => dispatch => {
  dispatch(
    providerDashboardAction.setProviderDISNumbers({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provider-dis-numbers`)
    .then(respnose => {
      dispatch(
        providerDashboardAction.setProviderDISNumbers({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerDashboardAction.setProviderDISNumbers({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
