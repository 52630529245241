import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row, Input } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { isObject } from "lodash"
import { RECEIPTTYPE } from "constants/data"

const ProviderInFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const expireFlatpickerfrom = useRef(null)
  const expireFlatpickerto = useRef(null)
  const receiptFlatpickerfrom = useRef(null)
  const receiptFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [project, setProject] = useState(null)
  const [batchNumber, setBatchNumber] = useState("")
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [receiptType, setReceiptType] = useState(null)
  const [expireDate, setExpireDate] = useState({
    from: null,
    to: null,
  })
  const [receiptDate, setReceiptDate] = useState({
    from: null,
    to: null,
  })
  const items = useSelector(state => state.providerDashboard.ProviderItems)
  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )
  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const ips = useSelector(state => state.providerDashboard.ProviderIps)

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let data = {}
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }

    data.project = isObject(project) ? project.value : null
    data.receiptType = isObject(receiptType) ? receiptType.value : null
    data.batchNumber = batchNumber
    data.invoice_number = invoiceNumber
    data.category = isObject(category) ? category.value : null
    data.expireDate = {
      from: expireDate.from ? expireDate.from : null,
      to: expireDate.to ? expireDate.to : null,
    }
    data.receiptDate = {
      from: receiptDate.from ? receiptDate.from : null,
      to: receiptDate.to ? receiptDate.to : null,
    }
    onAdvancedSearchSubmit(data)
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getProviderItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }
  const onProjectChangeHandler = value => {
    setProject(value)
  }

  const onReceiptTypeChangeHandler = value => {
    setReceiptType(value)
  }

  const onClearHandler = () => {
    expireFlatpickerfrom.current.flatpickr.clear()
    expireFlatpickerto.current.flatpickr.clear()
    receiptFlatpickerfrom.current.flatpickr.clear()
    receiptFlatpickerto.current.flatpickr.clear()
    setCategory(null)
    setItem(null)
    setExpireDate({ from: null, to: null })
    setReceiptDate({ from: null, to: null })
    setProject(null)
    setBatchNumber("")
    setInvoiceNumber("")
    onAdvancedSearchSubmit({})
    setReceiptType(null)
    dispatch(providerDashboardAction.resetProviderItems())
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Batch Number</Label>
            <Input
              name="batchNumber"
              value={batchNumber}
              onChange={value => {
                setBatchNumber(value.target.value)
              }}
              placeholder="Batch Number"
              type="text"
            />
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category || categories?.data[0]}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Expire From Date</Label>
            <Flatpickr
              ref={expireFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setExpireDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Expire To Date</Label>
            <Flatpickr
              ref={expireFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setExpireDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Receipt Type</Label>
            <Select
              options={RECEIPTTYPE}
              value={receiptType}
              onChange={onReceiptTypeChangeHandler}
            />
          </Col>
          <Col>
            <Label className="form-label">Invoice Number</Label>
            <Input
              name="invoiceNumber"
              value={invoiceNumber}
              onChange={value => {
                setInvoiceNumber(value.target.value)
              }}
              placeholder="Invoice Number"
              type="text"
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Receipt From Date</Label>
            <Flatpickr
              ref={receiptFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setReceiptDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Receipt To Date</Label>
            <Flatpickr
              ref={receiptFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setReceiptDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderInFilter
