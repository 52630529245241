import { useFormik } from "formik"

import * as Yup from "yup"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Table,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import CustomSelect from "components/Common/SelectBoxes/CustomSelect"
import { isEmpty, isObject } from "lodash"
import { useDispatch } from "react-redux"
import {
  getProviderFacilities,
  getProviderItems,
} from "store/provider-dashboard/provider-dashboard-action"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { isArray } from "lodash"
import { getProviderOutReceipts } from "store/provider-out/provider-out-action"
import ReceiptsList from "./ReceiptsList"
import SubmitedList from "./SubmitedList"
import {
  DISTRIBUTEDDETAIL,
  DISTRIBUTEDTYPE,
  DISTRIBUTIONREASON,
} from "constants/data"

const ProviderOutEditForm = ({
  onSubmit,
  loading,
  formMode,
  error,
  warehosues,
  providers,
  categories,
  items,
  distribution,
  ips,
  provinces,
  facilities,
  providerAccounts,
}) => {
  const [item, setItem] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [submited, setSubmited] = useState([])
  const [newQuantity, setNewQuantity] = useState(0)
  const submissionDateRef = useRef(null)
  const [facilityVisible, setFacilityVisible] = useState(false)
  const [customErrors, setCustomErrors] = useState({})

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files[0])
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const dispatch = useDispatch()
  const distributeValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      warehouse_id: distribution ? distribution?.warehouse_id : "",
      provider_id: distribution ? distribution?.provider_id : "",
      item_category_id: distribution ? distribution?.item_category_id : "",
      item_id: distribution ? distribution?.item_id : "",
      ip_id: distribution ? distribution?.ip_id : "",
      province_id: distribution ? distribution?.province_id : "",
      facility_id: distribution ? distribution?.facility_id : "",
      provider_in_id: distribution ? distribution?.provider_in_id : "",
      distributor: distribution ? distribution?.distributor : "",
      out_quantity: distribution ? distribution?.out_quantity : "",
      warehouse_disribute: distribution
        ? distribution?.warehouse_disribute
        : "",
      distributed_type: distribution ? distribution?.distributed_type : "",
      other_hfs: distribution ? distribution?.other_hfs : "",
      provided_for: distribution ? distribution?.provided_for : "",
      date: distribution ? distribution?.date : "",
      distribution_reason: distribution
        ? distribution?.distribution_reason
        : "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required("This in quantity IP is required"),
    }),

    onSubmit: values => {
      const warehouse_disribute = isObject(values?.warehouse_disribute)
        ? values?.warehouse_disribute.value
        : values?.warehouse_disribute
      if (warehouse_disribute === "HFs" && values?.facility_id === null) {
        setCustomErrors({ facility_id: "Facility is Required" })
        return
      } else {
        setCustomErrors({ facility_id: null })
        onSubmit(values, selectedFiles)
      }
    },
  })

  const resetAllForm = () => {
    distributeValidation.resetForm()
    setItem(null)
    submissionDateRef.current.flatpickr.clear()
  }

  const onWarehouseChangeHander = value => {
    distributeValidation.setFieldValue("warehouse_id", value)
  }

  const onProviderChangeHandler = value => {
    distributeValidation.setFieldValue("provider_id", value)
  }

  const onIpChangeHandler = value => {
    distributeValidation.setFieldValue("ip_id", value)
  }

  const onProvinceChangeHandler = value => {
    distributeValidation.setFieldValue("province_id", value)
    dispatch(getProviderFacilities(value.value))
  }

  const onItemCategoryChangeHandler = value => {
    distributeValidation.setFieldValue("item_category_id", value)
    dispatch(getProviderItems(value.value))
    distributeValidation.setFieldValue("item_id", null)
    setItem(null)
  }

  const onItemChangeHandler = value => {
    distributeValidation.setFieldValue("item_id", value)
    dispatch(getProviderOutReceipts(value.value))
  }

  const onDistributeDetailChangeHandler = value => {
    distributeValidation.setFieldValue("warehouse_disribute", value)
    // distributeValidation.setFieldValue("facility_id", "")
    if (value.value === "HFs") {
      setFacilityVisible(true)
    } else {
      setFacilityVisible(false)
    }
  }
  const onDistributedTypeChangeHandler = value => {
    distributeValidation.setFieldValue("distributed_type", value.value)
  }
  const onFacilityChangeHandler = value => {
    distributeValidation.setFieldValue("facility_id", value)
  }

  const filteredItems = useMemo(
    () =>
      items.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  useMemo(
    () =>
      setItem(
        distribution ? items.find(it => it.id === distribution?.item_id) : null
      ),
    [items]
  )

  useEffect(() => {
    dispatch(getProviderFacilities(distribution?.province_id))
    dispatch(getProviderItems(distribution?.item_category_id))
  }, [])

  const onChangeOutQuantityHandler = e => {
    if (e.target.value < 0) {
      return
    }
    distributeValidation.setFieldValue("out_quantity", e.target.value)
    setNewQuantity(e.target.value)
  }

  const onProvidedForChangehandler = value => {
    distributeValidation.setFieldValue("provided_for", value.value)
  }

  const getTotalOutQuantity = () => {
    const total =
      distribution?.provider_in?.in_qty_per_ip +
      distribution?.out_quantity -
      newQuantity
    if (total >= 0) {
      return total
    } else {
      return -1
    }
  }

  const onDistributionReasonChangeHandler = value => {
    const type = distributeValidation.values.distributed_type
    if (type == "Provider") {
      distributeValidation.setFieldValue("distribution_reason", value)
    }
  }

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Distribution" : "Add Distribution"}
          lgColumn={4}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/provider-distributions"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            distributeValidation.handleSubmit()
            return false
          }}
        >
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete file">
              <div className="p-2">
                <Row className="align-items-center">
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {selectedFiles.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{selectedFiles.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Account Type</Label>
                <CustomSelect
                  name="distributed_type"
                  options={DISTRIBUTEDTYPE}
                  isDisabled={true}
                  onChange={onDistributedTypeChangeHandler}
                  value={
                    !isEmpty(distributeValidation.values.distributed_type)
                      ? DISTRIBUTEDTYPE.find(
                          dis =>
                            dis.value ===
                            distributeValidation.values.distributed_type
                        )
                      : DISTRIBUTEDTYPE[0]
                  }
                />
              </Col>
              <Col>
                <Label className="form-label">Transaction Type</Label>
                <CustomSelect
                  name="distribution_reason"
                  options={DISTRIBUTIONREASON}
                  isDisabled={
                    distributeValidation.values.distributed_type == "IP"
                  }
                  value={
                    isObject(distributeValidation.values.distribution_reason)
                      ? distributeValidation.values.distribution_reason
                      : DISTRIBUTIONREASON.find(
                          reason =>
                            distributeValidation.values.distribution_reason ===
                            reason.value
                        ) || DISTRIBUTIONREASON[0]
                  }
                  onChange={onDistributionReasonChangeHandler}
                />
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Warehouses</Label>
                {warehosues && (
                  <CustomSelect
                    name="warehouse_id"
                    options={warehosues}
                    value={
                      isObject(distributeValidation.values.warehouse_id)
                        ? distributeValidation.values.warehouse_id
                        : warehosues.find(
                            ware =>
                              distributeValidation.values.warehouse_id ===
                              ware.value
                          ) || warehosues[0]
                    }
                    onChange={onWarehouseChangeHander}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Providers</Label>
                {providers && (
                  <CustomSelect
                    name="warehouse_id"
                    options={providers}
                    value={
                      isObject(distributeValidation.values.provider_id)
                        ? distributeValidation.values.provider_id
                        : providers.find(
                            prov =>
                              distributeValidation.values.provider_id ===
                              prov.value
                          ) || providers[0]
                    }
                    onChange={onProviderChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          {distribution?.distributed_type && (
            <div className="mb-3">
              <Row>
                {distribution?.distributed_type == "IP" ? (
                  <Col>
                    <Label className="form-label">IPs</Label>
                    {ips && (
                      <CustomSelect
                        name="warehouse_id"
                        options={ips}
                        isDisabled={true}
                        value={
                          isObject(distributeValidation.values.ip_id)
                            ? distributeValidation.values.ip_id
                            : ips.find(
                                p =>
                                  distributeValidation.values.ip_id === p.value
                              ) || ips[0]
                        }
                        onChange={onIpChangeHandler}
                      />
                    )}
                  </Col>
                ) : (
                  <Col>
                    <Label className="form-label">Provider Accounts</Label>
                    {providerAccounts && (
                      <CustomSelect
                        name="provided_for"
                        options={providerAccounts}
                        isDisabled={true}
                        value={
                          isObject(distributeValidation.values.provided_for)
                            ? distributeValidation.values.provided_for
                            : providerAccounts.find(
                                p =>
                                  distributeValidation.values.provided_for ===
                                  p.value
                              ) || { value: "", label: "" }
                        }
                        onChange={onProvidedForChangehandler}
                      />
                    )}
                  </Col>
                )}
                <Col>
                  <Label className="form-label">Distributor Name</Label>
                  <Input
                    name="distributor"
                    placeholder=" Distributor Name"
                    type="text"
                    disabled={
                      distributeValidation.values.distributed_type ===
                      "Provider"
                    }
                    onChange={distributeValidation.handleChange}
                    onBlur={distributeValidation.handleBlur}
                    value={distributeValidation.values.distributor || ""}
                    invalid={
                      (distributeValidation.touched.distributor &&
                        distributeValidation.errors.distributor) ||
                      error?.distributor?.[0]
                        ? true
                        : false
                    }
                  />
                  {(distributeValidation.touched.distributor &&
                    distributeValidation.errors.distributor) ||
                  error?.distributor?.[0] ? (
                    <FormFeedback type="invalid">
                      {distributeValidation.errors.distributor ||
                        error?.distributor?.[0]}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>
          )}

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Distribute Details</Label>
                <CustomSelect
                  name="distribute_detail"
                  options={DISTRIBUTEDDETAIL}
                  isDisabled={true}
                  value={
                    !isEmpty(distributeValidation.values.warehouse_disribute)
                      ? DISTRIBUTEDDETAIL.find(
                          dis =>
                            dis.value ===
                            distributeValidation.values.warehouse_disribute
                        )
                      : DISTRIBUTEDDETAIL[0]
                  }
                  onChange={onDistributeDetailChangeHandler}
                />
              </Col>
              <Col>
                <Label className="form-label">Provinces</Label>
                {provinces && (
                  <CustomSelect
                    name="province_id"
                    options={provinces}
                    value={
                      isObject(distributeValidation.values.province_id)
                        ? distributeValidation.values.province_id
                        : provinces.find(
                            prov =>
                              distributeValidation.values.province_id ===
                              prov.value
                          )
                    }
                    onChange={onProvinceChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Label className="form-label">Submission Date</Label>
            <Flatpickr
              ref={submissionDateRef}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              disabled={true}
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
                defaultDate: distributeValidation.values.date,
                maxDate: moment().format("YYYY-MM-DD"),
              }}
              onChange={value => {
                distributeValidation.setFieldValue(
                  "date",
                  moment(value[0]).format("YYYY-MM-DD")
                )
              }}
            />
            {(distributeValidation.touched.date &&
              distributeValidation.errors.date) ||
            error?.date?.[0] ? (
              <FormFeedback type="invalid">
                {distributeValidation.errors.date || error?.date?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          {facilities &&
            distributeValidation.values.distributed_type !== "Provider" && (
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Facilities</Label>
                    {facilities && (
                      <CustomSelect
                        name="facility_id"
                        options={facilities}
                        value={
                          isObject(distributeValidation.values.facility_id)
                            ? distributeValidation.values.facility_id
                            : facilities.find(
                                facil =>
                                  distributeValidation.values.facility_id ===
                                  facil.value
                              )
                        }
                        onChange={onFacilityChangeHandler}
                      />
                    )}
                  </div>
                </Col>
                {!isEmpty(distributeValidation.values.other_hfs) && (
                  <Col>
                    <Label className="form-label">Other HFs</Label>
                    <Input
                      name="other_hfs"
                      placeholder=" Other HFs"
                      type="text"
                      onChange={distributeValidation.handleChange}
                      onBlur={distributeValidation.handleBlur}
                      value={distributeValidation.values.other_hfs || ""}
                      invalid={
                        (distributeValidation.touched.other_hfs &&
                          distributeValidation.errors.other_hfs) ||
                        error?.other_hfs?.[0]
                          ? true
                          : false
                      }
                    />
                    {(distributeValidation.touched.other_hfs &&
                      distributeValidation.errors.other_hfs) ||
                    error?.other_hfs?.[0] ? (
                      <FormFeedback type="invalid">
                        {distributeValidation.errors.other_hfs ||
                          error?.other_hfs?.[0]}
                      </FormFeedback>
                    ) : null}
                  </Col>
                )}
              </Row>
            )}

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Item Categories</Label>
                {categories && (
                  <CustomSelect
                    name="item_category_id"
                    options={categories}
                    isDisabled={true}
                    value={
                      isObject(distributeValidation.values.item_category_id)
                        ? distributeValidation.values.item_category_id
                        : categories.filter(
                            cate =>
                              distributeValidation.values.item_category_id ===
                              cate.value
                          )
                    }
                    onChange={onItemCategoryChangeHandler}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Items</Label>
                {filteredItems && (
                  <CustomSelect
                    name="item_id"
                    options={filteredItems}
                    isDisabled={true}
                    value={
                      isObject(distributeValidation.values.item_id)
                        ? distributeValidation.values.item_id
                        : filteredItems.find(
                            item =>
                              distributeValidation.values.item_id === item.value
                          )
                    }
                    onChange={onItemChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          {distribution?.provider_in && item && (
            <div className="mb-3">
              <Table>
                <thead>
                  <tr>
                    <th>AFGA ITEM ID</th>
                    <th>IP-ITEM DESCRIPTION</th>
                    <th>AFGA UOM</th>
                    <th>IP UNIT UOM</th>
                    <th>BATCH NUMBER</th>
                    <th>EXPIRE DATE</th>
                    <th>QTY</th>
                    <th>OUT</th>
                    <th>BALANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.unfp_item_name}</td>
                    <td>{item?.ip_item_description}</td>
                    <td>
                      {item?.uom}{" "}
                      {item?.unfpa_uom_quantity > 1 && item?.unfpa_uom_quantity}
                    </td>
                    <td>
                      {item?.ip_unit_umo}{" "}
                      {item?.ip_uom_quantity > 1 && item?.ip_uom_quantity}
                    </td>
                    <td>{distribution?.provider_in?.batch_number}</td>
                    <td>{distribution?.provider_in?.expire_date}</td>
                    <td>{distribution?.provider_in?.in_qty_per_ip}</td>
                    {distributeValidation?.values?.distributed_type && (
                      <td>
                        <Input
                          type="number"
                          name="out_qty"
                          onWheel={e => e.target.blur()}
                          disabled={
                            distributeValidation?.values?.distributed_type ===
                            "Provider"
                              ? distribution?.providedFor?.in_qty_per_ip !==
                                distribution?.out_quantity
                              : distribution?.provinceIn?.in_qty !==
                                distribution?.out_quantity
                          }
                          value={
                            newQuantity !== 0
                              ? newQuantity
                              : distributeValidation.values.out_quantity
                          }
                          onChange={onChangeOutQuantityHandler}
                        />
                      </td>
                    )}

                    <td>
                      {getTotalOutQuantity() >= 0 ? (
                        getTotalOutQuantity()
                      ) : (
                        <span className="text-danger">
                          quantity is greater then receipt
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {error?.out_quantity && (
                <p className="text-danger">{error?.out_quantity}</p>
              )}
            </div>
          )}

          <div className="d-flex flex-wrap gap-2">
            <Button
              type="submit"
              color="primary"
              disabled={loading || getTotalOutQuantity() < 0}
            >
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Save"
                : "Update"}
            </Button>
          </div>
        </Form>
        <br />
        {submited.length > 0 && <SubmitedList submited={submited} />}

        {Object.values(customErrors).map((val, key) => (
          <p className="text-danger" key={key}>
            {val}
          </p>
        ))}
      </CardBody>
    </Card>
  )
}

export default ProviderOutEditForm
