import { downloadURL } from "api/APP_URL"
import React, { useState } from "react"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

const ProviderInDetailModal = ({ data, open, setOpen }) => {
  return (
    <Modal
      isOpen={open}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={() => {
        setOpen(!open)
      }}
      size="xl"
    >
      <div>
        <ModalHeader
          toggle={() => {
            setOpen(!open)
          }}
        >
          Summary
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <div
              style={{
                borderBottom: "0.8px solid #9facc2",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Row>
                <Col lg={4}>
                  <div>
                    <h6 className="detailTitle">
                      Current Quantity ( Per IP ):
                    </h6>
                    <p className="detailValue">{data?.in_qty_per_ip} </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div>
                    <h6 className="detailTitle">
                      {" "}
                      Current Quantity ( Per AFGA ):
                    </h6>
                    <p className="detailValue">
                      {" "}
                      {data?.in_qty_per_unfpa.toFixed(2)}
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div>
                    <h6 className="detailTitle">
                      {" "}
                      Current Value ( Per AFGA ):
                    </h6>
                    <p className="detailValue">
                      {data?.total_value.toFixed(2)}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginTop: 20,
              }}
            >
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Project</h6>
                  <p className="detailValue">{data?.project?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Warehouse</h6>
                  <p className="detailValue">{data?.warehouse?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Provider</h6>
                  <p className="detailValue">{data?.provider?.title}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Item Category</h6>
                  <p className="detailValue">{data?.item_category?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Item</h6>
                  <p className="detailValue">{data?.item?.item_name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">AFGA Item ID</h6>
                  <p className="detailValue">{data?.item?.unfp_item_name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">IP- Item description</h6>
                  <p className="detailValue">
                    {data?.item?.ip_item_description}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">AFGA Unit of measure (UOM)</h6>
                  <p className="detailValue">
                    {data?.item.uom} &nbsp;
                    {data?.unit_uom_quantity_per_unfpa > 1 &&
                      data?.unit_uom_quantity_per_unfpa}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">IP Unit UOM</h6>
                  <p className="detailValue">
                    {data?.item.ip_unit_umo} &nbsp;
                    {data?.unit_umo_quantity_per_ip > 1 &&
                      data?.unit_umo_quantity_per_ip}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Unit price (as per AFGA)</h6>
                  <p className="detailValue">{data?.unit_price_per_unfpa}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Unit price (as per IP UOM)</h6>
                  <p className="detailValue">{data?.unit_price_per_ip}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Batch / lot number</h6>
                  <p className="detailValue">{data?.batch_number}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Expiry / best before date</h6>
                  <p className="detailValue">{data?.expire_date}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">First Quantity (AFGA UOM)</h6>
                  <p className="detailValue">
                    {data?.first_in_qty_per_unfpa.toFixed(2)}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">First Quantity (IP UOM)</h6>
                  <p className="detailValue">{data?.first_in_qty_per_ip}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">First Total Value</h6>
                  <p className="detailValue">
                    {data?.first_total_value.toFixed(2)}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Lost Qty</h6>
                  <p className="detailValue">{data?.lost_quantity}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Lost Comment</h6>
                  <p className="detailValue">{data?.lost_comment}</p>
                </div>
              </Col>
            </Row>
            {data?.document && (
              <div>
                {" "}
                <br />
                <a
                  href={`${downloadURL}/${data?.document}`}
                  className="btn btn-sm btn-info"
                  target="_blank"
                  rel="noreferrer"
                  download={true}
                >
                  Download
                </a>
              </div>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ProviderInDetailModal
