import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import ProviderInFilter from "./ProviderInFilter"
import ProviderInTotalBox from "./ProviderInTotalBox"
import { downloadURL } from "api/APP_URL"

const ProviderInListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  onAllDeleteClicked,
  onAdvancedSearchSubmit,
  permissions,
  onClickDetail,
  onExportBtnClick,
  onUploadFileForMultipleReceipts,
  exportStatus,
  onRejectReceipt,
  onAcceiptReceipt,
}) => {
  const navigate = useNavigate()
  const [ids, setIds] = useState([])
  const [filterOpen, setFilterOpen] = useState(false)

  const onCheckboxClick = (e, id) => {
    const check = ids.includes(id)
    if (check) {
      const updated = ids.filter(myId => myId !== id)
      setIds(updated)
    } else {
      setIds(prev => {
        return [...prev, id]
      })
    }
  }

  const onAllDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure delete?",
    })
    if (results.isConfirmed) {
      onAllDeleteClicked(ids)
    }
  }
  const onUploadBtnhandler = async file => {
    const results = await CSAlert({
      title: "Are you sure upload file?",
    })
    if (results.isConfirmed) {
      onUploadFileForMultipleReceipts(ids, file)
    }
  }

  const onParentCheckChange = status => {
    if (status) {
      const all = data?.data.map(dt => dt.id)
      setIds(all)
    } else {
      setIds([])
    }
  }

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={true}
            rightBtnTitle={"Add Receipt"}
            rightBtnRoute={"/add-provider-receipts"}
            deleteBtn={permissions.includes("delete-provider-in")}
            onAllDeleteHandler={onAllDeleteHandler}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            onAdvancedSearch={onAdvancedSearchHandler}
            enableUploadBtn={true}
            onUploadBtnhandler={onUploadBtnhandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "item_name", name: "Item Name" },
              { value: "unfp_item_name", name: "AFGA Item Name" },
            ]}
          />
          <ProviderInTotalBox totals={data?.totals} />
          <Collapse isOpen={filterOpen}>
            <ProviderInFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            onParentCheckChange={onParentCheckChange}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "AFGA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "AFGA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "UNIT PRICE (AS PER AFGA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "QUANTITY (AFGA UOM)",
              "QUANTITY (IP UOM)",
              "COMMENT",
              "INVOICE NUMBER",
              "RECEIPT DATE",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pIn, index) => (
              <tr key={index}>
                <th scope="row">
                  {" "}
                  <div className="form-check form-checkbox-outline form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`checkbox-${pIn.id}`}
                      checked={ids.includes(pIn.id)}
                      onChange={e => onCheckboxClick(e, pIn.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${pIn.id}`}
                    >
                      {data?.meta?.from + index}
                    </label>
                  </div>
                </th>
                <td>{pIn?.project?.name}</td>
                <td>{pIn?.provider?.title}</td>
                <td>{pIn?.item?.unfp_item_name}</td>
                <td>{pIn?.item?.ip_item_description}</td>
                <td>
                  {pIn?.item.uom} &nbsp;
                  {pIn?.unit_uom_quantity_per_unfpa > 1 &&
                    pIn?.unit_uom_quantity_per_unfpa}
                </td>
                <td>
                  {pIn?.item.ip_unit_umo} &nbsp;
                  {pIn?.unit_umo_quantity_per_ip > 1 &&
                    pIn?.unit_umo_quantity_per_ip}
                </td>
                <td>{pIn.unit_price_per_unfpa}</td>
                <td>{pIn.unit_price_per_ip}</td>
                <td>{pIn.batch_number}</td>
                <td>{pIn.expire_date}</td>
                <td>{pIn.first_in_qty_per_unfpa}</td>
                <td>{pIn.first_in_qty_per_ip}</td>
                <td>{pIn.lost_quantity + " " + pIn.lost_comment}</td>
                <td
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {pIn?.invoice_number}
                </td>
                <td>{pIn.date}</td>
                <td>
                  {pIn.deleteStatus === 0 ? (
                    <React.Fragment>
                      {pIn?.provided_status === 0 ? (
                        <ActionColumns
                          data={pIn}
                          enableAccept={permissions.includes(
                            "update-provider-in"
                          )}
                          enableDelete={permissions.includes(
                            "delete-provider-in"
                          )}
                          enableDetail={true}
                          onAcceptClick={onAcceiptReceipt}
                          onClickDelete={onRejectReceipt}
                          onClickDetail={onClickDetail}
                          enableDocumentLink={pIn?.document}
                          documentLink={`${downloadURL}/${pIn?.document}`}
                        />
                      ) : (
                        <ActionColumns
                          data={pIn}
                          enableEdit={
                            permissions.includes("update-provider-in") &&
                            ["", null, 0].includes(pIn?.provided_by)
                          }
                          enableDelete={
                            permissions.includes("delete-provider-in") &&
                            ["", null, 0].includes(pIn?.provided_by)
                          }
                          enableDetail={true}
                          editRoute={`/edit-provider-receipts/${pIn.id}`}
                          onClickDelete={onClickDelete}
                          onClickDetail={onClickDetail}
                          enableDocumentLink={pIn?.document}
                          documentLink={`${downloadURL}/${pIn?.document}`}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <Button className="btn btn-info btn-sm">Pending</Button>
                  )}
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderInListComponent
