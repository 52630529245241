import React from "react"
import { Link } from "react-router-dom"
import { Table } from "reactstrap"

const ReceiptHealthListItems = ({ data }) => {
  return (
    <div className="table-responsive">
      <Table className="table mb-0">
        <thead>
          <tr>
            <th>SN</th>
            <th>AFGA ITEM ID</th>
            <th>IP- ITEM DESCRIPTION</th>
            <th style={{ whiteSpace: "nowrap" }}>AFGA UOM</th>
            <th style={{ whiteSpace: "nowrap" }}>IP UOM</th>
            <th
              style={{
                whiteSpace: "nowrap",
              }}
              className="text-info"
            >
              Active
            </th>
            <th
              style={{
                whiteSpace: "nowrap",
              }}
              className="text-info"
            >
              Near To Expire
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((item, index) => {
              let activeReceipts = !isNaN(parseInt(item?.totalActiveReceipt))
                ? parseInt(item?.totalActiveReceipt)
                : 0

              let expireReceipts = !isNaN(parseInt(item?.totalExpireReceipt))
                ? parseInt(item?.totalExpireReceipt)
                : 0

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.unfp_item_name}</td>
                  <td>{item.ip_item_description}</td>
                  <td>
                    {item.uom}{" "}
                    {item.unfpa_uom_quantity > 1 && item.unfpa_uom_quantity}
                  </td>
                  <td>
                    {item.ip_unit_umo}{" "}
                    {item.ip_uom_quantity > 1 && item.ip_uom_quantity}
                  </td>
                  <td className="text-info">{activeReceipts}</td>
                  <td className="text-info">{expireReceipts}</td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {data?.length === 0 && (
        <h5 style={{ textAlign: "center", padding: 20 }}>No Data Found</h5>
      )}
    </div>
  )
}

export default ReceiptHealthListItems
