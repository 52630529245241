import React, { useState } from "react"
import { Card, CardBody, Collapse, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderReceiptReportFilter from "./filter"
import ProviderConsumptionReporFilter from "./filter"
import Chart2 from "components/Charts/Chart2"
import PaginationLinks from "components/Pagination"

const ProviderConsumptionReportListComponent = ({
  data,
  error,
  loading,
  onChangeOrdering,
  onExportBtnClick,
  onSearchHandler,
  exportStatus,
  from,
  onPageClick,
  onChangeRowNumber,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  const onAdvancedSearchSubmit = data => {
    setFilter(data)
    onSearchHandler(data)
  }

  let consumptData = []
  if (data) {
    consumptData = data?.data?.map(stock => {
      const items = stock?.item_name
      const in_qty = !isNaN(parseInt(stock.totalQty))
        ? parseInt(stock.totalQty)
        : 0

      const out_qty = !isNaN(parseInt(stock.totalConsums))
        ? parseInt(stock.totalConsums)
        : 0

      const balance = in_qty - out_qty
      return { items, in_qty, out_qty, balance: balance }
    })
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            // pageRowNumberOptions={[20, 100, 150]}
            title={"CONSUMPTION REPORTS"}
            lgColumn={5}
            exportBtn={true}
            exportBtnLoading={exportStatus?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={from !== "Dashboard"}
            onChangeOrdering={onChangeOrdering}
            searchBox={false}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "date", name: "Date" },
              //   { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderConsumptionReporFilter
              loading={loading}
              error={error}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          {from === "Dashboard" && (
            <Chart2 data={consumptData} DistributionTitle={"Consumption"} />
          )}

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead>
                <tr>
                  {/* <th style={{ whiteSpace: "nowrap" }}>SN</th> */}
                  <th style={{ whiteSpace: "nowrap" }}>AFGA ITEM ID</th>
                  <th style={{ whiteSpace: "nowrap" }}>P- ITEM DESCRIPTION</th>
                  <th style={{ whiteSpace: "nowrap" }}>AFGA UOM</th>
                  <th style={{ whiteSpace: "nowrap" }}>IP UOM</th>
                  <th style={{ whiteSpace: "nowrap" }}>IP</th>
                  <th style={{ whiteSpace: "nowrap" }}>PROVINCE</th>
                  {filter?.facility && (
                    <th style={{ whiteSpace: "nowrap" }}>FACILITY</th>
                  )}

                  <th
                    style={{ whiteSpace: "nowrap", color: "rgba(0, 170, 85)" }}
                  >
                    RECEIPT
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap", color: "rgba(243, 32, 19)" }}
                  >
                    CONSUMPTION
                  </th>
                  <th style={{ whiteSpace: "nowrap", color: "rgba(0,0,255)" }}>
                    BALANCE
                  </th>
                  <th style={{ whiteSpace: "nowrap" }} className="text-info">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((consumption, index) => {
                  const balance =
                    consumption?.totalQty - consumption?.totalConsums
                  const percentage =
                    (consumption?.totalConsums / consumption?.totalQty) * 100
                  return (
                    <tr key={index}>
                      <td>{consumption?.item?.unfp_item_name}</td>
                      <td>{consumption?.item.ip_item_description}</td>
                      <td>
                        {consumption?.item.uom}{" "}
                        {consumption?.item.unfpa_uom_quantity > 1 &&
                          consumption?.item.unfpa_uom_quantity}
                      </td>
                      <td>
                        {consumption?.item.ip_unit_umo}{" "}
                        {consumption?.item.ip_uom_quantity > 1 &&
                          consumption?.item.ip_uom_quantity}
                      </td>
                      <td>{consumption?.ip?.name}</td>
                      <td>{consumption?.province?.province}</td>
                      {filter?.facility && (
                        <td>{consumption?.getFacility?.facility_name}</td>
                      )}

                      <td
                        style={{
                          whiteSpace: "nowrap",
                          color: "rgba(0, 170, 85)",
                        }}
                      >
                        {consumption?.totalQty}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          color: "rgba(243, 32, 19)",
                        }}
                      >
                        {consumption?.totalConsums}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap", color: "rgba(0,0,255)" }}
                      >
                        {balance}
                      </td>
                      <td className="text-info">{percentage.toFixed(1)} %</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {data?.meta && (
              <PaginationLinks meta={data?.meta} onPageClick={onPageClick} />
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderConsumptionReportListComponent
