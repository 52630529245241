import fileaxiosInstance from "api/fileAxiosInstance"
import SAlert from "components/Alert"
import { batch } from "react-redux"
import { providerOutAction } from "./provider-out-slice"
import axiosInstance from "api/axiosInstance"

export const getProviderOuts =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(providerOutAction.setLoading(true))
    url = url || "/v1/provider-outs"
    fileaxiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(providerOutAction.setError(null))
            dispatch(providerOutAction.setData(response.data))
            dispatch(providerOutAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(providerOutAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteManyProviderOut = ids => dispatch => {
  dispatch(providerOutAction.setLoading(true))
  fileaxiosInstance
    .post("/v1/provider-outs/delete/outs", { ids })
    .then(response => {
      dispatch(getProviderOuts())
      SAlert({ title: "Success", text: "Receipts successfully delete" })
      batch(() => {
        dispatch(providerOutAction.setError(null))
        dispatch(providerOutAction.setLoading(false))
      })
    })
    .catch(error => {
      if (error?.response?.data?.deleteError) {
        SAlert({
          text: error?.response?.data?.deleteError,
          status: "warning",
          timer: 5000,
        })
      }
      console.log(error)
    })
}

export const deleteProviderOut = id => dispatch => {
  dispatch(providerOutAction.setLoading(true))
  fileaxiosInstance
    .delete(`/v1/provider-outs/${id}`)
    .then(response => {
      dispatch(getProviderOuts())
      SAlert({ title: "Success", text: "Receipt successfully delete" })
      batch(() => {
        dispatch(providerOutAction.setError(null))
        dispatch(providerOutAction.setLoading(false))
      })
    })
    .catch(error => {
      if (error?.response?.data?.deleteError) {
        SAlert({
          text: error?.response?.data?.deleteError,
          status: "warning",
          timer: 5000,
        })
      }
      console.log(error)
    })
}

export const storeProviderOut = (requests, navigate) => dispatch => {
  dispatch(providerOutAction.setLoading(true))
  fileaxiosInstance
    .post(`/v1/provider-outs`, requests)
    .then(response => {
      SAlert({ title: "Success", text: "Receipt successfully inserted" })
      navigate("/provider-distributions")
      batch(() => {
        dispatch(providerOutAction.setError(null))
        dispatch(providerOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(providerOutAction.setLoading(false))
      dispatch(providerOutAction.setError(error?.response?.data?.errors))
      if (error?.response?.data?.error) {
        dispatch(
          providerOutAction.setStoreQuantityError(error?.response?.data?.error)
        )
      }
    })
}

export const updateProviderOut = (id, form, navigate) => dispatch => {
  dispatch(providerOutAction.setLoading(true))
  fileaxiosInstance
    .post(`/v1/provider-outs/${id}`, form)
    .then(response => {
      navigate("/provider-distributions")
      SAlert({ title: "Success", text: "Receipt successfully updated" })
      batch(() => {
        dispatch(providerOutAction.setError(null))
        dispatch(providerOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(providerOutAction.setLoading(false))
      dispatch(providerOutAction.setError(error?.response?.data?.errors))
      if (error?.response?.data?.err) {
        SAlert({
          title: "Success",
          text: error?.response?.data?.err,
          status: "warning",
        })
        navigate("/provider-distributions")
      }
    })
}

export const getByIdProviderOut = id => dispatch => {
  dispatch(providerOutAction.setLoading(true))
  fileaxiosInstance
    .get(`/v1/provider-outs/${id}`)
    .then(response => {
      batch(() => {
        dispatch(providerOutAction.setError(null))
        dispatch(providerOutAction.setProviderOut(response?.data))
        dispatch(providerOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(providerOutAction.setLoading(false))
      console.log(error)
    })
}

export const getProviderOutReceipts =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerOutAction.setProviderOutReceipts({
        loading: true,
        error: null,
        data: null,
      })
    )
    fileaxiosInstance
      .get(`/v1/get-provider-out-receipts`, { params: params })
      .then(respnose => {
        dispatch(
          providerOutAction.setProviderOutReceipts({
            loading: false,
            error: null,
            data: respnose?.data,
          })
        )
      })
      .catch(error => {
        dispatch(
          providerOutAction.setProviderOutReceipts({
            loading: false,
            error: error?.response,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProviderOutProviderAccounts = () => dispatch => {
  dispatch(
    providerOutAction.setProviderAccount({
      loading: true,
      error: null,
      data: null,
    })
  )
  fileaxiosInstance
    .get(`/v1/get-provider-out-providerAccounts`)
    .then(respnose => {
      dispatch(
        providerOutAction.setProviderAccount({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerOutAction.setProviderAccount({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderNonIps = ip_id => dispatch => {
  dispatch(
    providerOutAction.setNoneIps({
      loading: true,
      error: null,
      data: null,
    })
  )
  fileaxiosInstance
    .get(`/v1/get-provider-out-non-ips/${ip_id}`)
    .then(respnose => {
      dispatch(
        providerOutAction.setNoneIps({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        providerOutAction.setNoneIps({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviderOutProjects =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerOutAction.setProviderOutProjects({
        loading: true,
        error: null,
        data: null,
      })
    )

    fileaxiosInstance
      .get(`/v1/get-provider-out-projects`, { params: params })
      .then(respnose => {
        dispatch(
          providerOutAction.setProviderOutProjects({
            loading: false,
            error: null,
            data: respnose?.data,
          })
        )
      })
      .catch(error => {
        dispatch(
          providerOutAction.setProviderOutProjects({
            loading: false,
            error: error?.response,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const checkProviderOutInvoiceNumber = invoice_number => dispatch => {
  dispatch(
    providerOutAction.setInvoiceCheck({
      loading: true,
      status: false,
      msg: null,
    })
  )
  axiosInstance
    .get(`/v1/check-provider-out-invoice-number/${invoice_number}`)
    .then(response => {
      dispatch(
        providerOutAction.setInvoiceCheck({
          loading: false,
          status: response?.data?.status,
          msg: response?.data?.msg,
        })
      )
    })
    .catch(error => {
      console.log(error)
      dispatch(
        providerOutAction.setInvoiceCheck({
          loading: false,
          status: false,
          msg: null,
        })
      )
    })
}
