import React from "react"
import { Col, Row, Table } from "reactstrap"

const ProviderOutTotalBox = ({ totals }) => {
  return (
    <Row
      style={{
        marginBottom: 40,
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <Col lg={5}>
        <Table
          borderless={false}
          bordered={true}
          style={{ overflow: "hidden" }}
        >
          <tbody>
            <tr>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Distribution ( Per IP )
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.distributions}
              </td>
            </tr>
            <tr>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Distributions ( Per AFGA )
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.unfpa_distributions.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default ProviderOutTotalBox
