import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  receipt: {},
  uploadFile: { loading: false, error: null },
  items: { loading: false, error: null, data: [] },
  ProvidedStatus: { loading: false, error: null, data: null },
  invoiceCheck: { loading: false, status: false, msg: null },
}

const providerInSlice = createSlice({
  name: "providerIn",
  initialState,
  reducers: {
    setInvoiceCheck(state, action) {
      state.invoiceCheck = {
        loading: action.payload.loading,
        status: action.payload.status,
        msg: action.payload.msg,
      }
    },
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProviderIn(state, action) {
      state.receipt = action.payload
    },
    setUploadFile(state, action) {
      state.uploadFile = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setItems(state, action) {
      state.items = {
        data: action.payload.data,
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setProvidedStatus(state, action) {
      state.ProvidedStatus = {
        data: action.payload.data,
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.receipt = {}
      state.uploadFile = { loading: false, error: null }
    },
    resetItems(state) {
      state.items = { loading: false, error: null, data: [] }
    },
    resetProvidedStatus(state) {
      state.ProvidedStatus = { loading: false, error: null, data: null }
    },
    resetInvoiceCheck(state) {
      state.invoiceCheck = { loading: false, status: false, msg: null }
    },
  },
})

export const providerInAction = providerInSlice.actions

export default providerInSlice
