import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Table } from "reactstrap"
import moment from "moment"
import { isNull } from "lodash"

const ProviderStockItemList = ({ data, searchDate, provData }) => {
  const [monthCount, setMonthCount] = useState(1)

  useEffect(() => {
    let fromDate, toDate
    if (searchDate?.fromDate && searchDate?.toDate) {
      fromDate = moment(searchDate?.fromDate)
      toDate = moment(searchDate?.toDate)
    } else {
      fromDate = moment(provData?.firstDate?.date)
      toDate = moment(provData?.lastDate?.date)
    }
    setMonthCount(toDate.diff(fromDate, "months") + 1)
  }, [searchDate, provData])

  return (
    <div className="table-responsive">
      <Table className="table mb-0">
        <thead>
          <tr>
            <th>SN</th>
            {/* <th>UNFPA ITEM ID</th> */}
            <th>Project</th>
            <th>CATEGORY</th>
            <th>ITEM ID</th>
            <th>IP- ITEM DESCRIPTION</th>
            {/* <th style={{ whiteSpace: "nowrap" }}>UNFPA UOM</th>
            <th style={{ whiteSpace: "nowrap" }}>IP UOM</th> */}
            <th
              style={{
                whiteSpace: "nowrap",
              }}
              className="text-info"
            >
              Opening Balance
            </th>
            {/* <th
              style={{
                whiteSpace: "nowrap",
              }}
              className="text-info"
            >
              Lost Quantity
            </th> */}
            <th
              style={{
                color: "rgba(0, 170, 85)",
                whiteSpace: "nowrap",
              }}
            >
              RECEIPTS ( IN )
            </th>
            {/* <th
              style={{
                color: "rgba(0, 170, 85)",
                whiteSpace: "nowrap",
              }}
            >
              OTHER SOURCE RECEIPTS ( IN )
            </th> */}

            <th style={{ color: "rgba(243, 32, 19)", whiteSpace: "nowrap" }}>
              DISTRIBUTION ( OUT )
            </th>
            {/* <th style={{ color: "rgba(243, 32, 19)", whiteSpace: "nowrap" }}>
              EXCHANGE
            </th> */}
            <th
              style={{
                whiteSpace: "nowrap",
              }}
              className="text-info"
            >
              AMC
            </th>
            <th style={{ color: "rgba(0,0,255)", whiteSpace: "nowrap" }}>
              BALANCE
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((item, index) => {
              const exchange_distribution = isNull(item?.exchange_distribution)
                ? 0
                : item?.exchange_distribution

              let receipt = !isNaN(
                parseInt(item?.provider_ins_sum_first_in_qty_per_ip)
              )
                ? parseInt(item?.provider_ins_sum_first_in_qty_per_ip)
                : 0

              let distribution = !isNaN(
                parseInt(item?.provider_outs_sum_out_quantity)
              )
                ? parseInt(item?.provider_outs_sum_out_quantity)
                : 0

              let openingBalance = !isNaN(parseInt(item?.openingBalance))
                ? parseInt(item?.openingBalance)
                : 0
              let totalLostQuantity = !isNaN(parseInt(item?.totalLostQuantity))
                ? parseInt(item?.totalLostQuantity)
                : 0
              let beforDateDistribution = !isNaN(
                parseInt(item?.beforeDateDistribution)
              )
                ? parseInt(item?.beforeDateDistribution)
                : 0

              const other_source = isNull(item?.other_source_receipt)
                ? 0
                : item?.other_source_receipt

              let amc = distribution / monthCount
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{searchDate?.projectName}</td>
                  <td>{searchDate?.category?.label}</td>
                  <td>{item.unfp_item_name}</td>
                  <td>{item.ip_item_description}</td>
                  {/* <td>
                    {item.uom}{" "}
                    {item.unfpa_uom_quantity > 1 && item.unfpa_uom_quantity}
                  </td>
                  <td>
                    {item.ip_unit_umo}{" "}
                    {item.ip_uom_quantity > 1 && item.ip_uom_quantity}
                  </td> */}
                  {/* <td className="text-info">
                    {openingBalance}
                    &nbsp; - &nbsp;
                    <span style={{ color: "rgba(243, 32, 19)" }}>
                      {beforDateDistribution}
                    </span>
                  </td> */}
                  <td className="text-info">
                    {openingBalance - beforDateDistribution}
                  </td>
                  {/* <td className="text-info">{totalLostQuantity}</td> */}

                  <td>
                    <Link
                      to={`/dashboard/provider-item-ins?itemId=${item.id}&from=${searchDate.fromDate}&to=${searchDate.toDate}&type=receipt&project=${searchDate?.project}`}
                      style={{ color: "rgba(0, 170, 85)" }}
                    >
                      {Math.max(0, receipt - other_source)}
                    </Link>
                  </td>
                  {/* <td style={{ color: "rgba(0, 170, 85)" }}>{other_source}</td> */}
                  <td>
                    <Link
                      to={`/dashboard/provider-item-outs?itemId=${item.id}&from=${searchDate.fromDate}&to=${searchDate.toDate}&project=${searchDate?.project}`}
                      style={{ color: "rgba(243, 32, 19)" }}
                    >
                      {distribution}
                    </Link>
                  </td>
                  {/* <td style={{ color: "rgba(243, 32, 19)" }}>
                    {exchange_distribution}
                  </td> */}
                  <td className="text-info">{Math.round(amc)}</td>
                  <td>
                    <Link
                      to={`/dashboard/provider-item-ins?itemId=${item.id}&from=${searchDate.fromDate}&to=${searchDate.toDate}&type=balance&project=${searchDate?.project}`}
                      style={{ color: "rgba(0,0,255)" }}
                    >
                      {receipt +
                        openingBalance -
                        (distribution + beforDateDistribution)}
                    </Link>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {data?.length === 0 && (
        <h5 style={{ textAlign: "center", padding: 20 }}>No Data Found</h5>
      )}
    </div>
  )
}

export default ProviderStockItemList
