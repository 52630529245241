import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProviderOutItems } from "store/provider-dashboard/provider-dashboard-action"
import { useSearchParams } from "react-router-dom"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import ProviderChartOutItemsList from "components/ProviderDashboard/ProviderItems/ProviderChartOutItemsList"

const ProviderChartOutItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
    project: searchParams.get("project"),
  }
  const ProviderOutItems = useSelector(
    state => state.providerDashboard.ProviderOutItems
  )

  useEffect(() => {
    dispatch(getProviderOutItems(getParams))

    return () => {
      dispatch(providerDashboardAction.resetProviderOutItems())
    }
  }, [])

  return (
    <ProviderChartOutItemsList
      data={ProviderOutItems?.data?.data}
      loading={ProviderOutItems?.loading}
    />
  )
}

export default ProviderChartOutItems
