import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProviderInItems } from "store/provider-dashboard/provider-dashboard-action"
import { useSearchParams } from "react-router-dom"
import ProviderChartInItemsList from "components/ProviderDashboard/ProviderItems/ProviderChartInItemsList"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"

const ProviderChartInItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
    type: searchParams.get("type"),
    project: searchParams.get("project"),
  }
  const type = searchParams.get("type")
  const ProviderInItems = useSelector(
    state => state.providerDashboard.ProviderInItems
  )

  useEffect(() => {
    dispatch(getProviderInItems(getParams))

    return () => {
      dispatch(providerDashboardAction.resetProviderInItems())
    }
  }, [])

  return (
    <ProviderChartInItemsList
      data={ProviderInItems?.data?.data}
      loading={ProviderInItems?.loading}
      type={type}
    />
  )
}

export default ProviderChartInItems
