import SingleSelect from "components/Common/SelectBoxes/SingleSelect"
import React, { useMemo, useRef, useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Input, Label } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderStock } from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"

const ProviderStockFilter = ({
  onSubmit,
  onCategoryChange,
  provStockLoading,
  getSearchData,
  onProviderSearchHandler,
}) => {
  const providerIps = useSelector(state => state.providerDashboard.ProviderIps)
  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )
  const ProviderItems = useSelector(
    state => state.providerDashboard.ProviderItems
  )
  const ProviderItemCategoires = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const filteredItems = useMemo(
    () =>
      ProviderItems?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [ProviderItems]
  )

  const dispatch = useDispatch()

  const fromdateFlatpicker = useRef(null)
  const todateFlatpicker = useRef(null)
  const [Ips, setIps] = useState(null)
  const [Categories, setCategories] = useState(null)
  const [item, setItem] = useState(null)
  const [batchNumber, setBatchNumber] = useState("")
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [project, setProject] = useState(null)
  const [clear, setClear] = useState(false)

  const handleSubmit = () => {
    let searchs = {}
    searchs.ips = Ips ? Ips.value : null
    searchs.project = project ? project.value : null
    searchs.category = Categories ? Categories.value : null
    if (item) {
      searchs.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    searchs.batchNumber = batchNumber
    searchs.from = fromDate
    searchs.to = toDate
    onSubmit(searchs)
    onProviderSearchHandler(searchs)
    getSearchData({
      Ips,
      Categories,
      item,
      batchNumber,
      fromDate,
      toDate,
      project: project,
    })
  }

  const IpsChangeHandler = ip => {
    setIps(ip)
  }

  const onCategoryChangeHandler = category => {
    onCategoryChange(category.value)
    setCategories(category)
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }
  const onProjectChangeHandler = proj => {
    setProject(proj)
  }

  const onClearHandler = () => {
    fromdateFlatpicker.current.flatpickr.clear()
    todateFlatpicker.current.flatpickr.clear()
    setIps(null)
    setCategories(null)
    setItem(null)
    setProject(null)
    setBatchNumber("")
    setFromDate(null)
    setToDate(null)
    getSearchData({ Ips, Categories, item, batchNumber, fromDate, toDate })
    dispatch(providerDashboardAction.resetProviderItems())
    dispatch(getProviderStock())
    setClear(prev => {
      return !prev
    })
  }

  useEffect(() => {
    if (projects?.data && ProviderItemCategoires?.data) {
      const defaultSearch = {}
      defaultSearch.project = projects.data[0]?.value
      defaultSearch.category = ProviderItemCategoires?.data[0]?.value
      defaultSearch.fromDate = null
      defaultSearch.toDate = null
      onSubmit(defaultSearch)
      onProviderSearchHandler(defaultSearch)
      getSearchData({
        project: projects.data[0],
        Categories: ProviderItemCategoires?.data[0],
      })
    }
  }, [projects, ProviderItemCategoires, clear])

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Label className="form-label">Projects</Label>
        {projects?.data && (
          <Select
            options={[{ value: "all", label: "All" }, ...projects?.data]}
            value={project}
            onChange={onProjectChangeHandler}
          />
        )}
      </div>

      <div className="mb-3">
        <Label className="form-label">Item Categories</Label>
        {ProviderItemCategoires?.data && (
          <Select
            options={ProviderItemCategoires?.data}
            value={Categories || ProviderItemCategoires?.data[0]}
            onChange={onCategoryChangeHandler}
          />
        )}
      </div>

      {filteredItems && (
        <div className="mb-3">
          <Label className="form-label">Items</Label>
          <Select
            isMulti={true}
            options={[{ value: "all", label: "All" }, ...filteredItems]}
            value={item}
            onChange={onItemChangeHandler}
          />
        </div>
      )}
      <div className="mb-3">
        <Label className="form-label">Batch Number</Label>
        <Input
          name="name"
          value={batchNumber}
          onChange={value => {
            setBatchNumber(value.target.value)
          }}
          placeholder="Name"
          type="text"
        />
      </div>

      <div className="mb-3 ">
        <Label className="form-label">From Date</Label>
        <Flatpickr
          ref={fromdateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setFromDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
        <br />
        <Label className="form-label">To Date</Label>
        <Flatpickr
          ref={todateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setToDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
      </div>

      <div className="d-flex flex-wrap gap-2">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={provStockLoading}
        >
          {provStockLoading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderStockFilter
