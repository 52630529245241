import React from "react"
import { Link } from "react-router-dom"
import { Table } from "reactstrap"

const ProviderIPsStockItemlist = ({ data, searchDate, ips }) => {
  return (
    <div className="table-responsive">
      <Table className="table mb-0">
        <thead>
          <tr>
            <th>SN</th>
            <th>ITEM ID</th>
            <th>IP- ITEM DESCRIPTION</th>
            <th style={{ whiteSpace: "nowrap" }}>AFGA UOM</th>
            <th style={{ whiteSpace: "nowrap" }}>IP UOM</th>

            <th
              style={{
                color: "rgba(0, 170, 85)",
                whiteSpace: "nowrap",
              }}
            >
              RECEIPTS ( IN )
            </th>
            <th style={{ color: "rgba(243, 32, 19)", whiteSpace: "nowrap" }}>
              DISTRIBUTION ( OUT )
            </th>
            <th style={{ color: "rgba(0,0,255)", whiteSpace: "nowrap" }}>
              BALANCE
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((item, index) => {
              let receipt = !isNaN(
                parseInt(item?.province_ins_sum_first_in_qty)
              )
                ? parseInt(item?.province_ins_sum_first_in_qty)
                : 0

              let distribution = !isNaN(
                parseInt(item?.province_outs_sum_out_quantity)
              )
                ? parseInt(item?.province_outs_sum_out_quantity)
                : 0

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.unfp_item_name}</td>
                  <td>{item.ip_item_description}</td>
                  <td>
                    {item.uom}{" "}
                    {item.unfpa_uom_quantity > 1 && item.unfpa_uom_quantity}
                  </td>
                  <td>
                    {item.ip_unit_umo}{" "}
                    {item.ip_uom_quantity > 1 && item.ip_uom_quantity}
                  </td>

                  <td>
                    <Link
                      to={`/dashboard/provider-ips-item-ins?itemId=${item.id}&from=${searchDate.fromDate}&to=${searchDate.toDate}&ip_id=${ips.value}&type=receipt`}
                      style={{ color: "rgba(0, 170, 85)" }}
                    >
                      {receipt}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/dashboard/provider-ips-item-outs?itemId=${item.id}&from=${searchDate.fromDate}&to=${searchDate.toDate}&ip_id=${ips.value}`}
                      style={{ color: "rgba(243, 32, 19)" }}
                    >
                      {distribution}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/dashboard/provider-ips-item-ins?itemId=${item.id}&from=${searchDate.fromDate}&to=${searchDate.toDate}&ip_id=${ips.value}&type=balance`}
                      style={{ color: "rgba(0,0,255)" }}
                    >
                      {receipt - distribution}
                    </Link>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {data?.length === 0 && (
        <h5 style={{ textAlign: "center", padding: 20 }}>No Data Found</h5>
      )}
    </div>
  )
}

export default ProviderIPsStockItemlist
